import { useMemo } from 'react';
import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import allReducers from './reducers/rootReducers';

let store;

function initStore(initialState) {
    return createStore(
        allReducers,
        initialState,
        process.env.NODE_ENV === 'production'
            ? compose(applyMiddleware(thunkMiddleware))
            : composeWithDevTools(applyMiddleware(thunkMiddleware)),
    );
}

export const initializeStore = (preloadedState) => {
    let _store = store ?? initStore(preloadedState);

    // After navigating to a page with an initial Redux state, merge that state
    // with the current state in the store, and create a new store
    if (preloadedState && store) {
        _store = initStore({
            ...store.getState(),
            ...preloadedState,
        });
        // Reset the current store
        store = undefined;
    }

    // For SSG and SSR always create a new store
    if (typeof window === 'undefined') return _store;
    // Create the store once in the client
    if (!store) store = _store;

    return _store;
};

export function useStore(initialState) {
    const store = useMemo(() => initializeStore(initialState), [initialState]);
    return store;
}

// const allReducers = combineReducers({
//   UI: uiReducer,
//   userData: userReducer,
// });
// const makeStore = () => {
//   const composeEnhancers =
//     process.env.NODE_ENV !== "production" ? composeWithDevTools : compose;

//   return createStore(allReducers, composeEnhancers(applyMiddleware(thunk)));
// };

// const wrapper = createWrapper(makeStore, { debug: true });

// export default wrapper;
