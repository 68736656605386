import {
    SET_LOTTERY_CATEGORY,
    SET_POTENTIAL_WINNING_DATA,
    SET_BET_OPTIONS,
    SET_RESULT_OPTIONS,
    SET_OVER_OPTIONS,
    SET_UNDER_OPTIONS,
    SET_BOOSTER_OPTIONS,
    RESET_BET_SLIPS,
    REMOVE_BET_SLIP,
    SET_GAME_BET_OPTION,
    SET_GAME_RESULT_OPTION,
    SET_GAME_OVER_OPTION,
    SET_GAME_UNDER_OPTION,
    SET_BETTYPE_DESCRIPTION,
    UPDATE_BET_SLIPS,
    UPDATE_IS_ADDING_BETSLIPS,
    SELECT_GAME,
    UNSELECT_GAME,
    SET_COMBO_DESCRIPTION,
    RESET_COMBO_DESCRIPTION,
    SET_ALL_BET_OPTIONS,
    SET_SELECTED_OVER_OPTION_OBJ,
    SET_SELECTED_UNDER_OPTION_OBJ,
    RESET_SELECTED_OVER_OPTION_OBJ,
    RESET_SELECTED_UNDER_OPTION_OBJ,
} from 'redux/types/types';

const initialState = {
    lotteryCategory: 'salary4life',
    lotteryBetOptions: {},
    lotteryResultOptions: {},
    lotteryOverOptions: {},
    lotteryUnderOptions: {},
    lotteryBoosterOptions: {},
    potentialWinningData: {},
    gameBetOption: '',
    activeGameInstance: {},
    gameResultOption: {},
    gameOverOption: 0,
    gameUnderOption: 0,
    overOptionObj: {},
    underOptionObj: {},
    betTypeDescription: '',
    comboDescription: '',
    isAddingBetSlip: false,
    betSlips: [],
    allLotteryBetOptions: {},
};

export default function lotteryReducer(state = initialState, action) {
    switch (action.type) {
        case SET_LOTTERY_CATEGORY:
            return {
                ...state,
                lotteryCategory: action.payload,
            };
        case SET_BET_OPTIONS:
            return {
                ...state,
                lotteryBetOptions: action.payload,
            };
        case SET_RESULT_OPTIONS:
            return {
                ...state,
                lotteryResultOptions: action.payload,
            };
        case SET_OVER_OPTIONS:
            return {
                ...state,
                lotteryOverOptions: action.payload,
            };
        case SET_UNDER_OPTIONS:
            return {
                ...state,
                lotteryUnderOptions: action.payload,
            };
        case SET_BOOSTER_OPTIONS:
            return {
                ...state,
                lotteryBoosterOptions: action.payload,
            };
        case SET_GAME_BET_OPTION:
            return {
                ...state,
                gameBetOption: action.payload,
            };
        case SET_GAME_RESULT_OPTION:
            return {
                ...state,
                gameResultOption: action.payload,
            };
        case SET_GAME_OVER_OPTION:
            return {
                ...state,
                gameOverOption: action.payload,
            };
        case SET_GAME_UNDER_OPTION:
            return {
                ...state,
                gameUnderOption: action.payload,
            };
        case SET_BETTYPE_DESCRIPTION:
            return {
                ...state,
                betTypeDescription: action.payload,
            };
        case UPDATE_BET_SLIPS:
            return {
                ...state,
                betSlips: action.payload,
            };
        case RESET_BET_SLIPS:
            return {
                ...state,
                betSlips: action.payload,
            };
        case REMOVE_BET_SLIP:
            return {
                ...state,
                betSlips: action.payload,
            };
        case SET_POTENTIAL_WINNING_DATA:
            return {
                ...state,
                potentialWinningData: action.payload,
            };
        case UPDATE_IS_ADDING_BETSLIPS:
            return {
                ...state,
                isAddingBetSlip: action.payload,
            };
        case SELECT_GAME:
            return {
                ...state,
                activeGameInstance: action.payload,
            };
        case UNSELECT_GAME:
            return {
                ...state,
                activeGameInstance: {},
            };
        case SET_COMBO_DESCRIPTION:
            return {
                ...state,
                comboDescription: action.payload,
            };
        case RESET_COMBO_DESCRIPTION:
            return {
                ...state,
                comboDescription: action.payload,
            };
        case SET_ALL_BET_OPTIONS:
            return {
                ...state,
                allLotteryBetOptions: action.payload,
            };
        case SET_SELECTED_OVER_OPTION_OBJ:
            return {
                ...state,
                underOptionObj: null,
                overOptionObj: action.payload,
            };
        case SET_SELECTED_UNDER_OPTION_OBJ:
            return {
                ...state,
                overOptionObj: null,
                underOptionObj: action.payload,
            };
        case RESET_SELECTED_OVER_OPTION_OBJ:
            return {
                ...state,
                overOptionObj: null,
            };
        case RESET_SELECTED_UNDER_OPTION_OBJ:
            return {
                ...state,
                underOptionObj: null,
            };

        default:
            return state;
    }
}
