import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { SET_USER, SET_WALLET_INFO } from '../types/types';
import { getUserToken } from 'utils';

export const TOKEN_NAME = 'somedata';

export function loginUser(data) {
    return (dispatch) => {
        dispatch({
            type: 'LOGINING_USER',
        });

        axios
            .post(`${process.env.NEXT_PUBLIC_BASE_URL}/admin-auth`, data)
            .then((res) => {
                const token = res.data;
                localStorage.setItem(TOKEN_NAME, token);
                const decodedToken = jwtDecode(token);
                dispatch(setUser(decodedToken));

                dispatch({
                    type: 'COMPLETE_LOGINING_USER',
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: 'COMPLETE_LOGINING_USER',
                });
            });
    };
}

export function setUser(data) {
    return { type: SET_USER, payload: data };
}

export function authenticateUser(data) {
    return (dispatch) => {
        dispatch({ type: 'AUTHENTICATE_USER' });
    };
}

export function clearSignUpErrors() {
    return (dispatch) => {
        dispatch({
            type: 'CLEAR_SIGNUP_ERRORS',
        });
    };
}
export function setRegistrationState(data) {
    return (dispatch) => {
        dispatch({
            type: 'SET_REGISTRATION_STATE',
            payload: data,
        });
    };
}

// function to logout the user
export function logOutUser() {
    return (dispatch) => {
        dispatch({ type: 'LOGOUT_USER' });
        if (typeof window === 'object') {
            // window.location.href = '/login';
            localStorage.removeItem(TOKEN_NAME);
        }
    };
}

export const setAuthorizationHeader = () => (dispatch) => {
    if (localStorage.getItem(TOKEN_NAME)) {
        try {
            const token = localStorage.getItem(TOKEN_NAME);
            const decodedToken = jwtDecode(token);
            if (decodedToken !== undefined || decodedToken !== null) {
                dispatch(setUser(decodedToken));

                if (Date.now() >= decodedToken.exp * 1000) {
                    dispatch(logOutUser());
                }
            }
        } catch (error) {
            dispatch(logOutUser());
            console.log(error);
        }
    }
};

// Action to set user wallet information
export function handleSetWalletInfo() {
    return async (dispatch, getState) => {
        try {
            const token = getUserToken();
            const decodedToken = jwtDecode(token);
            if (decodedToken !== undefined || decodedToken !== null) {
                dispatch(setUser(decodedToken));
            }

            const url = decodedToken?.isAgent
                ? `${process.env.NEXT_PUBLIC_BASE_URL}/agent/fetch-agent/${decodedToken?.userId}`
                : `${process.env.NEXT_PUBLIC_BASE_URL}/user/fetch-user/${decodedToken?.userId}`;

            const res = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            });

            dispatch({ type: SET_WALLET_INFO, payload: res?.data?.data?.data });
        } catch (error) {
            console.log('errorrrrr', error);
        }
    };
}

export function handleRefreshToken() {
    return async (dispatch, getState) => {
        try {
            const token = getUserToken();

            const url = `${process.env.NEXT_PUBLIC_BASE_URL}/user/refresh-login`;

            const res = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            });

            const refreshToken = res.data?.data?.data?.token;
            localStorage.setItem(TOKEN_NAME, refreshToken);
            const decodedToken = jwtDecode(token);
            if (decodedToken !== undefined || decodedToken !== null) {
                dispatch(setUser(decodedToken));
            }

            // dispatch({ type: SET_WALLET_INFO, payload: res?.data?.data?.data });
        } catch (error) {
            console.log('errorrrrr', error);
        }
    };
}
