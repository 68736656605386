export const SHOW_TV_FULLSCREEN = 'SHOW_TV_FULLSCREEN';
export const HIDE_TV_FULLSCREEN = 'HIDE_TV_FULLSCREEN';
export const LIGHT_THEME = 'LIGHT_THEME';
export const DARK_THEME = 'DARK_THEME';
export const COLORED_THEME = 'COLORED_THEME';
export const SHOW_SNACKBAR = 'SHOW_SNACKBAR';
export const HIDE_SNACKBAR = 'HIDE_SNACKBAR';

// User Types

export const LOGOUT_USER = 'LOGOUT_USER';
export const SET_USER = 'SET_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const SET_USER_HEADER = 'SET_USER_HEADER';
export const SET_WALLET_INFO = 'SET_WALLET_INFO';

// Lottery Types

export const SET_ALL_BET_OPTIONS = 'SET_ALL_BET_OPTIONS';
export const SET_LOTTERY_CATEGORY = 'SET_LOTTERY_CATEGORY';
export const SET_COMBO_DESCRIPTION = 'SET_COMBO_DESCRIPTION';
export const SET_BET_OPTIONS = 'SET_BET_OPTIONS';
export const SET_RESULT_OPTIONS = 'SET_RESULT_OPTIONS';
export const SET_OVER_OPTIONS = 'SET_OVER_OPTIONS';
export const SET_UNDER_OPTIONS = 'SET_UNDER_OPTIONS';
export const SET_BOOSTER_OPTIONS = 'SET_BOOSTER_OPTIONS';
export const SET_POTENTIAL_WINNING_DATA = 'SET_POTENTIAL_WINNING_DATA';
export const SELECT_GAME = 'SELECT_GAME';
export const RESET_BET_SLIPS = 'RESET_BET_SLIPS';
export const REMOVE_BET_SLIP = 'REMOVE_BET_SLIP';
export const SET_GAME_BET_OPTION = 'SET_GAME_BET_OPTION';
export const SET_GAME_RESULT_OPTION = 'SET_GAME_RESULT_OPTION';
export const SET_GAME_OVER_OPTION = 'SET_GAME_OVER_OPTION';
export const SET_GAME_UNDER_OPTION = 'SET_GAME_UNDER_OPTION';
export const SET_BETTYPE_DESCRIPTION = 'SET_BETTYPE_DESCRIPTION';
export const GET_BET_SLIPS = 'GET_BET_SLIPS';
export const GET_RESULT_OPTIONS = 'GET_RESULT_OPTIONS';
export const GET_BET_OPTIONS = 'GET_BET_OPTIONS';
export const GET_LOTTERY_CATEGORY = 'GET_LOTTERY_CATEGORY';
export const GET_GAME_BET_OPTION = 'GET_GAME_BET_OPTION';
export const GET_GAME_RESULT_OPTION = 'GET_GAME_RESULT_OPTION';
export const GET_GAME_OVER_OPTION = 'GET_GAME_OVER_OPTION';
export const GET_GAME_UNDER_OPTION = 'GET_GAME_UNDER_OPTION';
export const GET_BOOSTER_OPTIONS = 'GET_BOOSTER_OPTIONS';
export const GET_BETTYPE_DESCRIPTION = 'GET_BETTYPE_DESCRIPTION';
export const UPDATE_BET_SLIPS = 'UPDATE_BET_SLIPS';
export const UPDATE_IS_ADDING_BETSLIPS = 'UPDATE_IS_ADDING_BETSLIPS';
export const UNSELECT_GAME = 'UNSELECT_GAME';
export const RESET_COMBO_DESCRIPTION = 'RESET_COMBO_DESCRIPTION';
export const SET_SELECTED_OVER_OPTION_OBJ = 'SET_SELECTED_OVER_OPTION_OBJ';
export const SET_SELECTED_UNDER_OPTION_OBJ = 'SET_SELECTED_UNDER_OPTION_OBJ';
export const RESET_SELECTED_OVER_OPTION_OBJ = 'RESET_SELECTED_OVER_OPTION_OBJ';
export const RESET_SELECTED_UNDER_OPTION_OBJ = 'RESET_SELECTED_UNDER_OPTION_OBJ';

// Commission Types

export const OPEN_COMMISSION_DIALOG = 'OPEN_COMMISSION_DIALOG';
export const CLOSE_COMMISSION_DIALOG = 'CLOSE_COMMISSION_DIALOG';

// Banks Types
export const GET_BANKS = 'GET_BANKS';
export const SET_BANK_USAGE_COUNT = 'SET_BANK_USAGE_COUNT';
export const ADD_BANK_USAGE_COUNT = 'ADD_BANK_USAGE_COUNT';

// TV Screen Types
export const RESET_COUNT = 'RESET_COUNT';
export const INCREASE_COUNT = 'INCREASE_COUNT';
export const UPDATE_TV_TIMER_ID = 'UPDATE_TV_TIMER_ID';
export const UPDATE_TV_TIMER_COUNT = 'UPDATE_TV_TIMER_COUNT';
export const RESET_GENERAL_TIMERID = 'RESET_GENERAL_TIMERID';
