import {
    INCREASE_COUNT,
    RESET_COUNT,
    RESET_GENERAL_TIMERID,
    UPDATE_TV_TIMER_COUNT,
    UPDATE_TV_TIMER_ID,
} from 'redux/types/types';
import {
    AFRIKENO,
    BINGO_BALLS,
    CASHOUT,
    EAZY_WIN,
    FIVE_0F_NINETY,
    generateRandomId,
    KENO,
    LEGENDARY_LOTTO,
    LOTTO,
    LOTTO_CONTINENTAL,
    MARIO_KENO,
    MEGA_7,
    META_4,
    METRO,
    PERFECT_BALLS,
    POOL_OF_FAME,
    SALARY_FOR_LIFE,
    SEVEN49,
    SIX_FOUR_NINE,
} from 'utils';

const initialState = {
    resetCount: false,
    currentCount: 0,
    timerId: generateRandomId(),
    lotteryCategoriesTimer: [
        {
            name: MEGA_7,
            timer: generateRandomId(),
            count: 0,
            startAnimation: true,
        },
        {
            name: SALARY_FOR_LIFE,
            timer: generateRandomId(),
            count: 0,
            startAnimation: true,
        },
        {
            name: LEGENDARY_LOTTO,
            timer: generateRandomId(),
            count: 0,
            startAnimation: true,
        },
        {
            name: FIVE_0F_NINETY,
            timer: generateRandomId(),
            count: 0,
            startAnimation: true,
        },
        {
            name: AFRIKENO,
            timer: generateRandomId(),
            count: 0,
            startAnimation: true,
        },
        {
            name: EAZY_WIN,
            timer: generateRandomId(),
            count: 0,
            startAnimation: true,
        },
        {
            name: KENO,
            timer: generateRandomId(),
            count: 0,
            startAnimation: true,
        },
        {
            name: SIX_FOUR_NINE,
            timer: generateRandomId(),
            count: 0,
            startAnimation: true,
        },
        {
            name: PERFECT_BALLS,
            timer: generateRandomId(),
            count: 0,
            startAnimation: true,
        },
        {
            name: META_4,
            timer: generateRandomId(),
            count: 0,
            startAnimation: true,
        },
        {
            name: METRO,
            timer: generateRandomId(),
            count: 0,
            startAnimation: true,
        },
        {
            name: CASHOUT,
            timer: generateRandomId(),
            count: 0,
            startAnimation: true,
        },
        {
            name: LOTTO,
            timer: generateRandomId(),
            count: 0,
            startAnimation: true,
        },
        {
            name: SEVEN49,
            timer: generateRandomId(),
            count: 0,
            startAnimation: true,
        },
        {
            name: POOL_OF_FAME,
            timer: generateRandomId(),
            count: 0,
            startAnimation: true,
        },
        {
            name: MARIO_KENO,
            timer: generateRandomId(),
            count: 0,
            startAnimation: true,
        },
        {
            name: LOTTO_CONTINENTAL,
            timer: generateRandomId(),
            count: 0,
            startAnimation: true,
        },
        {
            name: BINGO_BALLS,
            timer: generateRandomId(),
            count: 0,
            startAnimation: true,
        },
    ],
};

export default function tvScreenReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_COUNT: {
            return {
                ...state,
                currentCount: 0,
                resetCount: !state.resetCount,
            };
        }
        case INCREASE_COUNT: {
            return { ...state, currentCount: state.currentCount + 1 };
        }
        case RESET_GENERAL_TIMERID: {
            return { ...state, timerId: generateRandomId() };
        }
        case UPDATE_TV_TIMER_ID: {
            return { ...state, lotteryCategoriesTimer: action.payload };
        }
        case UPDATE_TV_TIMER_COUNT: {
            return { ...state, lotteryCategoriesTimer: action.payload };
        }

        default:
            return state;
    }
}
