export const SIX49_RED_BG =
    'radial-gradient(circle at 30% 25%,#ed5439 6px,#ec0000 8%,#580101 60%,#9f1800 100%)';
export const SIX49_YELLOW_BG =
    'radial-gradient(circle at 30% 25%,#f9c21e 6px,#cba200 8%,rgb(187, 137, 0) 60%,#8a6800 100%)';
export const SIX49_BLUE_BG =
    'radial-gradient(circle at 35% 20%,#014981 6px,#0126a1 8%,#152ae7 60%, #4340f0 100%)';
export const SIX49_GRAY_BG =
    'linear-gradient(171deg, rgba(55,54,54,1) 0%, rgba(86,84,84,1) 46%, rgba(40,42,47,1) 67%)';

export const EAZY_WIN_RED_BG =
    'radial-gradient(circle at 30% 25%,#ed5439 6px,#ec0000 8%,#580101 60%,#9f1800 100%)';
export const EAZY_WIN_YELLOW_BG =
    'radial-gradient(circle at 30% 25%,#f9c21e 6px,#cba200 8%,rgb(187, 137, 0) 60%,#8a6800 100%)';
export const EAZY_WIN_BLUE_BG =
    'radial-gradient(circle at 35% 20%,#014981 6px,#0126a1 8%,#152ae7 60%, #4340f0 100%)';
export const EAZY_WIN_PURPLE_BG =
    'radial-gradient(circle at 30% 25%,#bc05b6 6px,#60015b 8%,#700284 60%,#860074 100%)';
export const EAZY_WIN_GREEN_BG =
    'radial-gradient(circle at 30% 25%,#8ce36d 6px,#0fa80f 8%,#054301 60%,#4dee20 100%)';

export const METRO_RED_BG =
    'radial-gradient(circle at 30% 25%,#ed5439 6px,#ec0000 8%,#580101 60%,#9f1800 100%)';
export const METRO_YELLOW_BG =
    'radial-gradient(circle at 30% 25%,#f9c21e 6px,#cba200 8%,rgb(187, 137, 0) 60%,#8a6800 100%)';
export const METRO_BLUE_BG =
    'radial-gradient(circle at 35% 20%,#014981 6px,#0126a1 8%,#152ae7 60%, #4340f0 100%)';
export const METRO_PURPLE_BG =
    'radial-gradient(circle at 30% 25%,#bc05b6 6px,#60015b 8%,#700284 60%,#860074 100%)';
export const METRO_GREEN_BG =
    'radial-gradient(circle at 30% 25%,#8ce36d 6px,#0fa80f 8%,#054301 60%,#4dee20 100%)';

export const Six49BallsCounts = [
    {
        num: 1,
        bg: SIX49_RED_BG,
    },
    {
        num: 2,
        bg: SIX49_YELLOW_BG,
    },
    {
        num: 3,
        bg: SIX49_BLUE_BG,
    },
    {
        num: 4,
        bg: SIX49_RED_BG,
    },
    {
        num: 5,
        bg: SIX49_YELLOW_BG,
    },
    {
        num: 6,
        bg: SIX49_BLUE_BG,
    },
    {
        num: 7,
        bg: SIX49_RED_BG,
    },
    {
        num: 8,
        bg: SIX49_YELLOW_BG,
    },
    {
        num: 9,
        bg: SIX49_BLUE_BG,
    },
    {
        num: 10,
        bg: SIX49_RED_BG,
    },
    {
        num: 11,
        bg: SIX49_YELLOW_BG,
    },
    {
        num: 12,
        bg: SIX49_BLUE_BG,
    },
    {
        num: 13,
        bg: SIX49_RED_BG,
    },
    {
        num: 14,
        bg: SIX49_YELLOW_BG,
    },
    {
        num: 15,
        bg: SIX49_BLUE_BG,
    },
    {
        num: 16,
        bg: SIX49_RED_BG,
    },
    {
        num: 17,
        bg: SIX49_YELLOW_BG,
    },
    {
        num: 18,
        bg: SIX49_BLUE_BG,
    },
    {
        num: 19,
        bg: SIX49_RED_BG,
    },
    {
        num: 20,
        bg: SIX49_YELLOW_BG,
    },
    {
        num: 21,
        bg: SIX49_BLUE_BG,
    },
    {
        num: 22,
        bg: SIX49_RED_BG,
    },
    {
        num: 23,
        bg: SIX49_YELLOW_BG,
    },
    {
        num: 24,
        bg: SIX49_BLUE_BG,
    },
    {
        num: 25,
        bg: SIX49_RED_BG,
    },
    {
        num: 26,
        bg: SIX49_YELLOW_BG,
    },
    {
        num: 27,
        bg: SIX49_BLUE_BG,
    },
    {
        num: 28,
        bg: SIX49_RED_BG,
    },
    {
        num: 29,
        bg: SIX49_YELLOW_BG,
    },
    {
        num: 30,
        bg: SIX49_BLUE_BG,
    },
    {
        num: 31,
        bg: SIX49_RED_BG,
    },
    {
        num: 32,
        bg: SIX49_YELLOW_BG,
    },
    {
        num: 33,
        bg: SIX49_BLUE_BG,
    },
    {
        num: 34,
        bg: SIX49_RED_BG,
    },
    {
        num: 35,
        bg: SIX49_YELLOW_BG,
    },
    {
        num: 36,
        bg: SIX49_BLUE_BG,
    },
    {
        num: 37,
        bg: SIX49_RED_BG,
    },
    {
        num: 38,
        bg: SIX49_YELLOW_BG,
    },
    {
        num: 39,
        bg: SIX49_BLUE_BG,
    },
    {
        num: 40,
        bg: SIX49_RED_BG,
    },
    {
        num: 41,
        bg: SIX49_YELLOW_BG,
    },
    {
        num: 42,
        bg: SIX49_BLUE_BG,
    },
    {
        num: 43,
        bg: SIX49_RED_BG,
    },
    {
        num: 44,
        bg: SIX49_YELLOW_BG,
    },
    {
        num: 45,
        bg: SIX49_BLUE_BG,
    },
    {
        num: 46,
        bg: SIX49_RED_BG,
    },
    {
        num: 47,
        bg: SIX49_YELLOW_BG,
    },
    {
        num: 48,
        bg: SIX49_BLUE_BG,
    },
    {
        num: 49,
        bg: SIX49_GRAY_BG,
    },
];

export const EazyWinBallsCounts = [
    {
        num: 1,
        bg: EAZY_WIN_YELLOW_BG,
    },
    {
        num: 2,
        bg: EAZY_WIN_GREEN_BG,
    },
    {
        num: 3,
        bg: EAZY_WIN_BLUE_BG,
    },
    {
        num: 4,
        bg: EAZY_WIN_PURPLE_BG,
    },
    {
        num: 5,
        bg: EAZY_WIN_RED_BG,
    },
    {
        num: 6,
        bg: EAZY_WIN_YELLOW_BG,
    },
    {
        num: 7,
        bg: EAZY_WIN_GREEN_BG,
    },
    {
        num: 8,
        bg: EAZY_WIN_BLUE_BG,
    },
    {
        num: 9,
        bg: EAZY_WIN_PURPLE_BG,
    },
    {
        num: 10,
        bg: SIX49_RED_BG,
    },
];

export const MetroBallsCounts = [
    {
        num: 1,
        bg: METRO_BLUE_BG,
    },
    {
        num: 2,
        bg: METRO_YELLOW_BG,
    },
    {
        num: 3,
        bg: METRO_PURPLE_BG,
    },
    {
        num: 4,
        bg: METRO_RED_BG,
    },
    {
        num: 5,
        bg: METRO_GREEN_BG,
    },
    {
        num: 6,
        bg: METRO_BLUE_BG,
    },
    {
        num: 7,
        bg: METRO_YELLOW_BG,
    },
    {
        num: 8,
        bg: METRO_PURPLE_BG,
    },
    {
        num: 9,
        bg: METRO_RED_BG,
    },
    {
        num: 10,
        bg: METRO_GREEN_BG,
    },

    {
        num: 11,
        bg: METRO_BLUE_BG,
    },
    {
        num: 12,
        bg: METRO_YELLOW_BG,
    },
    {
        num: 13,
        bg: METRO_PURPLE_BG,
    },
    {
        num: 14,
        bg: METRO_RED_BG,
    },
    {
        num: 15,
        bg: METRO_GREEN_BG,
    },

    {
        num: 16,
        bg: METRO_BLUE_BG,
    },
    {
        num: 17,
        bg: METRO_YELLOW_BG,
    },
    {
        num: 18,
        bg: METRO_PURPLE_BG,
    },
    {
        num: 19,
        bg: METRO_RED_BG,
    },
    {
        num: 20,
        bg: METRO_GREEN_BG,
    },
    {
        num: 21,
        bg: METRO_BLUE_BG,
    },
    {
        num: 22,
        bg: METRO_YELLOW_BG,
    },
    {
        num: 23,
        bg: METRO_PURPLE_BG,
    },
    {
        num: 24,
        bg: METRO_RED_BG,
    },
    {
        num: 25,
        bg: METRO_GREEN_BG,
    },

    {
        num: 26,
        bg: METRO_BLUE_BG,
    },
    {
        num: 27,
        bg: METRO_YELLOW_BG,
    },
    {
        num: 28,
        bg: METRO_PURPLE_BG,
    },
    {
        num: 29,
        bg: METRO_RED_BG,
    },
];
