import 'rsuite/dist/rsuite.min.css';
import '../public/styles/global-style.css';
import React, { useState, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { Provider } from 'react-redux';
import { useStore } from '../redux/store';
import { handleSetWalletInfo, logOutUser, setUser, TOKEN_NAME } from '../redux/actions/userActions';
import jwtDecode from 'jwt-decode';
import { getFCMToken } from 'utils/firebaseInit';
import Analytics from 'components/HOC/Analytics';
import { handleGetBanks } from 'redux/actions/banksAction';
import { handleGetAllBetOptions } from 'redux/actions/lotteryActions2';
import { getUserToken } from 'utils';
import axios from 'axios';

const CustomApp = ({ Component, pageProps }) => {
    const store = useStore(pageProps.initialReduxState);

    const [tokenFound, setTokenFound] = useState('');
    const [notification, setNotification] = useState({ title: '', body: '' });
    const url = `${process.env.NEXT_PUBLIC_BASE_URL}/user/update-activity-status`;
    const handleSetUserOnline = async () => {
        try {
            const token = getUserToken();
            if (!token) {
                return;
            }

            const payload = {
                activityStatus: 'online',
            };
            const { data } = await axios.put(url, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            });
        } catch (error) {
            console.log('errorrrrr', error);
        }
    };
    const handleSetUserOffline = async () => {
        try {
            const token = getUserToken();
            if (!token) {
                return;
            }

            const payload = {
                activityStatus: 'offline',
            };
            const { data } = await axios.put(url, payload, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            });
        } catch (error) {
            console.log('errorrrrr', error);
        }
    };
    useEffect(() => {
        getFCMToken(setTokenFound);
    }, []);
    const setAuthorizationHeader = () => {
        if (localStorage.getItem(TOKEN_NAME)) {
            try {
                const token = localStorage.getItem(TOKEN_NAME);
                const decodedToken = jwtDecode(token);

                if (decodedToken !== undefined || decodedToken !== null) {
                    // console.log('ffffffffffffffff');
                    store.dispatch(setUser(decodedToken));
                    if (Date.now() >= decodedToken.exp * 1000) {
                        store.dispatch(logOutUser());
                    }
                }
            } catch (error) {
                store.dispatch(logOutUser());
                console.log(error);
            }
        }
    };
    useEffect(() => {
        setAuthorizationHeader();
        store.dispatch(handleSetWalletInfo());
        store.dispatch(handleGetBanks());
        store.dispatch(handleGetAllBetOptions());
    }, []);
    useEffect(() => {
        // add the user to active users list

        document.addEventListener('visibilitychange', (event) => {
            if (document.visibilityState == 'visible') {
                handleSetUserOnline();
            } else {
                handleSetUserOffline();
            }
        });
        return () => {
            document.removeEventListener('visibilitychange', (event) => {
                handleSetUserOffline();
            });
        };
    }, []);
    return (
        <>
            <Provider store={store}>
                <Analytics>
                    <Toaster />
                    <Component {...pageProps} />
                </Analytics>
            </Provider>
        </>
    );
};

export default CustomApp;
