import { ADD_BANK_USAGE_COUNT, GET_BANKS, SET_BANK_USAGE_COUNT } from 'redux/types/types';

const initialState = {
    banks: [],
    count: 0,
};

export default function bankReducer(state = initialState, action) {
    switch (action.type) {
        case GET_BANKS: {
            return {
                ...state,
                banks: action.payload,
            };
        }
        case SET_BANK_USAGE_COUNT: {
            return {
                ...state,
                count: action.payload,
            };
        }
        case ADD_BANK_USAGE_COUNT: {
            return {
                ...state,
                count: state.count + 1,
            };
        }

        default:
            return state;
    }
}
