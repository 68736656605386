import axios from 'axios';
import {
    CASHOUT,
    getUserToken,
    KENO,
    MEGA_7,
    META_4,
    META_4_BETOPTIONS,
    METRO,
    PERFECT_BALLS,
    PERFECT_BALLS_BETOPTIONS,
    safeJSONParse,
    SALARY_FOR_LIFE,
    SEVEN49,
    SIX_FOUR_NINE,
    POOL_OF_FAME,
    POOL_OF_FAME_BETOPTIONS,
    LOTTO,
    LOTTO_BETOPTIONS,
    FIVE_0F_NINETY,
    MARIO_KENO,
    MARIO_KENO_BETOPTIONS,
    LOTTO_CONTINENTAL,
    LOTTO_CONTINENTAL_BET_OPTIONS,
    BINGO_BALLS,
    BINGO_BALLS_BETOPTIONS,
    SEVEN49_BETOPTIONS,
    CASHOUT_BETOPTIONS,
    METRO_BETOPTIONS,
    SIX_FOUR_NINE_BETOPTIONS,
    KENO_BETOPTIONS,
    AFRIKENO_BETOPTIONS,
    EAZY_WIN_BETOPTIONS,
    MEGA_7_BETOPTIONS,
    LEGENDARY_LOTTO_BETOPTIONS,
    SALARY_FOR_LIFE_BETOPTIONS,
    FIVE_0F_NINETY_BETOPTIONS,
    ALL_LOTTERIES_BETOPTIONS,
    LEGENDARY_LOTTO,
} from 'utils';
import { SET_ALL_BET_OPTIONS } from '../types/types';
import { EAZY_WIN, AFRIKENO } from 'utils';
export const EMPTY_STRING = '';
export const SESSION_STORAGE_CATEGORY = 'SESSION_STORAGE_CATEGORY';

export const getBetOptions = async (category) => {
    try {
        const token = getUserToken();
        const res = await axios.get(
            `${process.env.NEXT_PUBLIC_BASE_URL}/site-settings/fetch-setting-by-slug/${category}`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            },
        );
        // console.log('resssssss', res?.data?.data);
        let content = safeJSONParse(res.data?.data?.data?.content);
        let newContent = {};

        content.forEach((element) => {
            newContent[element.value] = element;
        });

        return newContent;
    } catch (error) {
        console.log('err', error);
    }
};

export function handleGetAllBetOptions() {
    // const urlSearchParams = new URLSearchParams(window.location.search);
    // const params = Object.fromEntries(urlSearchParams.entries());
    // return console.log('ssssssssss', params);
    return async (dispatch, getState) => {
        try {
            const fiveNinety = await getBetOptions(FIVE_0F_NINETY_BETOPTIONS);
            const salary4life = await getBetOptions(SALARY_FOR_LIFE_BETOPTIONS);
            const legendarylotto = await getBetOptions(LEGENDARY_LOTTO_BETOPTIONS);
            const mega7Data = await getBetOptions(MEGA_7_BETOPTIONS);
            const eazyWinData = await getBetOptions(EAZY_WIN_BETOPTIONS);
            const afrikenoData = await getBetOptions(AFRIKENO_BETOPTIONS);
            const kenoData = await getBetOptions(KENO_BETOPTIONS);
            const six49Data = await getBetOptions(SIX_FOUR_NINE_BETOPTIONS);
            const cashoutData = await getBetOptions(CASHOUT_BETOPTIONS);
            const meta4Data = await getBetOptions(META_4_BETOPTIONS);
            const perfectData = await getBetOptions(PERFECT_BALLS_BETOPTIONS);
            const metroData = await getBetOptions(METRO_BETOPTIONS);
            const seven49Data = await getBetOptions(SEVEN49_BETOPTIONS);
            const poolofFameData = await getBetOptions(POOL_OF_FAME_BETOPTIONS);
            const lottoData = await getBetOptions(LOTTO_BETOPTIONS);
            const marioKenoData = await getBetOptions(MARIO_KENO_BETOPTIONS);
            const lottoContinentalData = await getBetOptions(LOTTO_CONTINENTAL_BET_OPTIONS);
            const bingoBallData = await getBetOptions(BINGO_BALLS_BETOPTIONS);

            let content = {};
            content[FIVE_0F_NINETY] = fiveNinety;
            content[SALARY_FOR_LIFE] = salary4life;
            content[LEGENDARY_LOTTO] = legendarylotto;
            content[MEGA_7] = mega7Data;
            content[EAZY_WIN] = eazyWinData;
            content[AFRIKENO] = afrikenoData;
            content[KENO] = kenoData;
            content[SIX_FOUR_NINE] = six49Data;
            content[CASHOUT] = cashoutData;
            content[META_4] = meta4Data;
            content[PERFECT_BALLS] = perfectData;
            content[METRO] = metroData;
            content[SEVEN49] = seven49Data;
            content[POOL_OF_FAME] = poolofFameData;
            content[LOTTO] = lottoData;
            content[MARIO_KENO] = marioKenoData;
            content[LOTTO_CONTINENTAL] = lottoContinentalData;
            content[BINGO_BALLS] = bingoBallData;

            typeof window !== 'undefined' &&
                sessionStorage.setItem(ALL_LOTTERIES_BETOPTIONS, JSON.stringify(content));
            dispatch({
                type: SET_ALL_BET_OPTIONS,
                payload: content,
            });
        } catch (error) {
            console.log('err', error);
        }
    };
}
