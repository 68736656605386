import { HYDRATE } from 'next-redux-wrapper';
import {
    HIDE_SNACKBAR,
    SHOW_SNACKBAR,
    SHOW_TV_FULLSCREEN,
    HIDE_TV_FULLSCREEN,
    COLORED_THEME,
} from '../types/types';

const initialState = {
    open: false,
    message: '',
    severity: '',
    showFullTVScreen: false,
    darkTheme: false,
    colorTheme: false,
};

const snackbarReducer = (state = initialState, action) => {
    switch (action.type) {
        case HYDRATE:
            return {
                ...state,
            };
        case HIDE_SNACKBAR:
            return {
                ...state,
                open: false,
            };

        case SHOW_SNACKBAR:
            return {
                ...state,
                ...action.payload,
            };
        case SHOW_TV_FULLSCREEN:
            return {
                ...state,
                ...action.payload,
            };
        case HIDE_TV_FULLSCREEN:
            return {
                ...state,
                ...action.payload,
            };
        case COLORED_THEME:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
};

export default snackbarReducer;
