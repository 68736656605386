import { OPEN_COMMISSION_DIALOG, CLOSE_COMMISSION_DIALOG } from 'redux/types/types';

const initialState = {
    openCommissionDialog: false,
};

export default function commissionReducer(state = initialState, action) {
    switch (action.type) {
        case OPEN_COMMISSION_DIALOG: {
            return {
                ...state,
                openCommissionDialog: true,
            };
        }
        case CLOSE_COMMISSION_DIALOG: {
            return {
                ...state,
                openCommissionDialog: false,
            };
        }

        default:
            return state;
    }
}
