import { SET_WALLET_INFO } from 'redux/types/types';

const initialState = {
    isAuthenticated: false,
    userData: {},
    walletInformation: {},
};

export default function userReducer(state = initialState, action) {
    switch (action.type) {
        case 'LOGIN_USER':
            return {
                ...state,
                isAuthenticated: true,
                userData: action.payload,
            };
        case 'LOGOUT_USER':
            return {
                ...state,
                isAuthenticated: false,
            };
        case 'AUTHENTICATE_USER':
            return {
                ...state,
                isAuthenticated: true,
            };

        case 'SET_USER':
            return {
                ...state,
                isAuthenticated: true,
                userData: action.payload,
            };

        case SET_WALLET_INFO: {
            return {
                ...state,
                walletInformation: action.payload,
            };
        }

        default:
            return state;
    }
}
