import axios from 'axios';
import React from 'react';

const Analytics = ({ children }) => {
    const [sending, setSending] = React.useState(true);
    const handleSendAnalytics = async () => {
        try {
            const { data } = await axios.get(
                `${process.env.NEXT_PUBLIC_BASE_URL}/analytics/track-device`,
                {
                    headers: {
                        'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                    },
                },
            );

            setSending(false);
        } catch (error) {
            setSending(false);
        }
    };
    React.useEffect(() => {
        handleSendAnalytics();
    }, []);

    if (sending) {
        null;
    }
    return <>{children}</>;
};

export default Analytics;
