import axios from 'axios';
import { ADD_BANK_USAGE_COUNT, GET_BANKS } from '../types/types';
import { BANK_LIST, BANK_USAGE_COUNT, getUserToken, safeJSONParse } from 'utils';

export function handleGetBanks() {
    return async (dispatch, getState) => {
        try {
            const bankData = typeof window === 'object' && sessionStorage.getItem(BANK_LIST);
            const usageCount =
                typeof window === 'object' && sessionStorage.getItem(BANK_USAGE_COUNT);
            if (bankData) {
                if (usageCount < 100) {
                    const banks = safeJSONParse(bankData);
                    return dispatch({ type: GET_BANKS, payload: banks });
                }
            }

            const token = getUserToken();

            const url = `${process.env.NEXT_PUBLIC_BASE_URL}/fetch-banks`;

            const { data } = await axios.get(url, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                },
            });

            if (typeof window === 'object') {
                sessionStorage.setItem(BANK_LIST, JSON.stringify(data?.data?.data));
            }

            return dispatch({ type: GET_BANKS, payload: data?.data?.data });
        } catch (error) {
            console.log('errorrrrr', error);
        }
    };
}

export function handleAddBankUsageCount() {
    return async (dispatch, getState) => {
        try {
            const { count } = getState().bankReducer;
            typeof window === 'object' && sessionStorage.setItem(BANK_USAGE_COUNT, count + 1);
            if (bankData) {
                return dispatch({ type: ADD_BANK_USAGE_COUNT });
            }
        } catch (error) {
            console.log('errorrrrr', error);
        }
    };
}

export function handleResetBankUsageCount() {
    return async (dispatch, getState) => {
        try {
            const { count } = getState().bankReducer;
            typeof window === 'object' && sessionStorage.setItem(BANK_USAGE_COUNT, 0);
            if (bankData) {
                return dispatch({ type: SET_BANK_USAGE_COUNT, payload: 0 });
            }
        } catch (error) {
            console.log('errorrrrr', error);
        }
    };
}
