import { combineReducers } from 'redux';

import uiReducer from './uiReducer';
import userReducer from './userReducer';
import lotteryReducer from './lotteryReducer';
import commissionReducer from './commReducer';
import bankReducer from './bankReducer';
import tvScreenReducer from './tvScreenReducer';

const allReducers = combineReducers({
    UI: uiReducer,
    userData: userReducer,
    lotteryData: lotteryReducer,
    commData: commissionReducer,
    bankReducer: bankReducer,
    tvScreen: tvScreenReducer,
});

export default allReducers;
