import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyD7oZG29Z0WcZR8TbSgGANvSfdaEdih9CE',
    authDomain: 'lixom-betja.firebaseapp.com',
    projectId: 'lixom-betja',
    storageBucket: 'lixom-betja.appspot.com',
    messagingSenderId: '584589312758',
    appId: '1:584589312758:web:413f8c268d1af0526922d7',
    measurementId: 'G-TLTQ4KK4D7',
};

initializeApp(firebaseConfig);

const messaging = getMessaging();

export async function getFCMToken() {
    try {
        const token = await getToken(messaging, {
            vapidKey: 'AIzaSyD7oZG29Z0WcZR8TbSgGANvSfdaEdih9CE',
        });
        // console.log('tokennnnnnnn', token);
        if (currentToken) {
            setTokenFound(true);
        } else {
            setTokenFound(false);
        }
        return token;
    } catch (e) {
        console.log('getFCMToken error', e);
        return undefined;
    }
}

// getToken(messaging, {
//     vapidKey: VAPIDKEY,
//   })
//     .then((currentToken) => {
//       if (currentToken) {
//         sendTokenToServer(currentToken);
//         updateUIForPushEnabled(currentToken);
//       } else {
//         // Show permission request.
//         console.log(
//           "No registration token available. Request permission to generate one."
//         );
//         // Show permission UI.
//         updateUIForPushPermissionRequired();
//         setTokenSentToServer(false);
//       }
//     })
//     .catch((err) => {
//       console.log("An error occurred while retrieving token. ", err);
//       showToken("Error retrieving registration token. ", err);
//       setTokenSentToServer(false);
//     });
// }

export const onMessageListener = () =>
    new Promise((resolve) => {
        messaging?.onMessage((payload) => {
            resolve(payload);
        });
    });
